import styled from "styled-components"

import { CallAssistActivateContent } from "src/components/CallAssistActivate/CallAssistActivateContent"
import { CallAssistActivateProvider } from "src/components/CallAssistActivate/CallAssistActivateContext"
import { DebugSettings } from "src/components/CallAssistActivate/DebugSettings"
import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import { breakpoint } from "src/constants/breakpoints"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { PageHelmet } from "src/ui/Helmet/PageHelmet"
import imageUrl from "src/ui/images/photo-call-assist.jpg"
import portraitImageUrl from "src/ui/images/photo-call-assist-portrait.jpg"
import { MCard } from "src/ui/MCard/MCard"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function CallAssistActivate() {
  return (
    <CallAssistActivateProvider>
      <CallAssistBackdrop>
        <CallAssistActivateContent />
      </CallAssistBackdrop>
    </CallAssistActivateProvider>
  )
}

function CallAssistBackdrop({ children }: { children: React.ReactNode }) {
  const { t, langKeys } = useTranslate()
  const { debug } = useFlags()

  return (
    <Box>
      <PageHelmet helmet={t(langKeys.call_assist)} />

      <ImageBackdrop
        landscapeImageUrl={imageUrl}
        portraitImageUrl={portraitImageUrl}
      >
        <StyledCard>{children}</StyledCard>
      </ImageBackdrop>

      <Show if={!!debug}>
        <DebugSettingsBox>
          <ExpandableSection
            title={"Debug settings"}
            id="call-assist-debug"
            storeState
          >
            <DebugSettings open={true} />
          </ExpandableSection>
        </DebugSettingsBox>
      </Show>
    </Box>
  )
}

const desktopUp = breakpoint.mediumUp

const Box = styled.div`
  position: relative;
`

const DebugSettingsBox = styled.div`
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;
  background: white;
  padding: 0.5rem;
`

const StyledCard = styled(MCard)`
  max-width: 650px;
  padding: ${spacing.L} ${spacing.L};
  @media (${desktopUp}) {
    padding: ${spacing.XL};
  }
`
